import { graphql } from 'gatsby'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Seo from '../../components/Seo'
import { PAGE_VIEWED } from '../../constants/metrics'
import BasicLayout from '../../layouts/BasicLayout'
import MetricsService from '../../services/metricsService'
import { useTranslation } from 'react-i18next'
import { Icon } from '@momentum-ui/react'
import Link from '../../components/Link'
import { linkRenderer } from '../../utils/markdown.utils'
import ResourceCard from '../../components/ResourceCardSection/ResourceCard'
import { makeKebabCase, getRandomInt } from '../../utils/general.utils'

export interface IPartnerShowCaseProps {
  data: {
    strapiPartnerShowCases: {
      industry: string
      headquarters: string
      size: string
      content: string
      description: string
      name: string
      link: string
      logo: {
        url: string
        alternativeText: string
      }
    }
    allStrapiPartnerShowCases: {
      nodes: [
        {
          name: string
          logo: {
            url: string
            alternativeText: string
          }
          footerTitleText: string
          footerBodyText: string
        }
      ]
    }
  }
}

export interface IPartnerFooterCard {
  name: string
  logo: {
    url: string
    alternativeText: string
  }
  footerTitleText: string
  footerBodyText: string
}

export const query = graphql`
  query PartnerShowCaseQuery($name: String!) {
    strapiPartnerShowCases(name: { eq: $name }) {
      name
      industry
      headquarters
      size
      content
      description
      link
      logo {
        url
        alternativeText
      }
    }
    allStrapiPartnerShowCases {
      nodes {
        name
        logo {
          url
          alternativeText
        }
        footerTitleText
        footerBodyText
      }
    }
  }
`

const PartnerShowcases: React.FC<IPartnerShowCaseProps> = ({
  data,
}): JSX.Element => {
  const {
    name,
    content,
    industry,
    size,
    description,
    link,
    logo,
    headquarters,
  } = data.strapiPartnerShowCases

  const randomizeFooterCards = (
    partnerCards: IPartnerFooterCard[]
  ): IPartnerFooterCard[] => {
    const partnerCardCopy = [...partnerCards]
    let resultArr: IPartnerFooterCard[] = []
    const maxCards = partnerCardCopy.length > 3 ? 3 : partnerCardCopy.length
    while (resultArr.length < maxCards) {
      const randomIndex = getRandomInt(0, partnerCardCopy.length)
      resultArr = [...resultArr, ...partnerCardCopy.splice(randomIndex, 1)]
    }
    return resultArr
  }

  const partnerFooterCards = randomizeFooterCards(
    data.allStrapiPartnerShowCases.nodes.filter(
      (card: IPartnerFooterCard, i) => {
        return card.name !== name
      }
    )
  )

  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()
  const title = `${name} Partner Showcase`

  metricsService.track(PAGE_VIEWED, {
    pageName: title,
  })

  return (
    <BasicLayout>
      <Seo title={title}></Seo>
      <article className="partner-showcase">
        <section className="partner-showcase-banner">
          <div className="banner-container">
            <h1>{name}</h1>
            <ReactMarkdown
              source={description}
              renderers={linkRenderer}
              escapeHtml={false}
            />
          </div>
        </section>
        <section className="partner-showcase-body">
          <div className="body-content">
            <ReactMarkdown
              source={content}
              renderers={linkRenderer}
              escapeHtml={false}
            />
          </div>
          <div className="body-right-pane">
            <div className="partner-logo-container">
              <img src={logo.url} alt={logo.alternativeText} />
            </div>
            <div className="partner-info">
              <div className="partner-info-row">
                <div className="partner-info-row-cell">
                  <p>{t('partnerShowcase.industry')}</p>
                  <p>{industry}</p>
                </div>
                <div className="partner-info-row-cell">
                  <p>{t('partnerShowcase.size')}</p>
                  <p>{size}</p>
                </div>
              </div>
              <div className="partner-info-row">
                <div className="partner-info-row-cell">
                  <p>{t('partnerShowcase.headquarters')}</p>
                  <p>{headquarters}</p>
                </div>
                <div className="partner-info-row-cell">
                  <p>{t('partnerShowcase.site')}</p>
                  <Link
                    to={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="default-link"
                  >
                    {t('partnerShowcase.goToSite')} <Icon name="pop-out_12" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
      <section className="partner-showcase-footer-section">
        <h2>{t('partnerShowcase.footerSectionHeader')}</h2>
        <div className="partner-showcase-footer-container">
          {partnerFooterCards.map((partner: IPartnerFooterCard, i: number) => {
            const { name, logo, footerTitleText, footerBodyText } = partner

            return (
              <ResourceCard
                href={`/partner-showcase/${makeKebabCase(name)}`}
                hrefText={t('partnerShowcase.seeStory')}
                label={footerTitleText}
                body={footerBodyText}
                img={<img src={logo.url} alt={logo.alternativeText} />}
                partner={name}
                key={i}
              />
            )
          })}
        </div>
      </section>
    </BasicLayout>
  )
}

export default PartnerShowcases
