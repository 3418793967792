import React from 'react'
import Link from '../components/Link'

/* eslint-disable react/display-name */
export const linkRenderer = {
  link: ({
    href,
    children,
  }: {
    href: string
    children: { [key: string]: any }[]
  }): JSX.Element => {
    return (
      <Link
        to={href}
        target="_blank"
        rel="noopener noreferrer"
        className="default-link"
      >
        {children[0].props.value}
      </Link>
    )
  },
}

/* eslint-disable react/display-name */
export const removeLinkRenderer = {
  link: ({
    href,
    children,
  }: {
    href: string
    children: { [key: string]: any }[]
  }): JSX.Element => {
    return <>{children[0].props.value}</>
  },
}
